<template>
  <div>
    <a-form-model :model="form" layout="inline" style="padding-bottom: 30px">
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="标签名称">
            <a-input
                v-model="form.labelName"
                placeholder="请输入三级标签名称"
                style="width: 260px"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-button @click="handleReset">重置</a-button>
          <a-button type="primary" @click="handleSearch" style="margin-left: 15px">查询</a-button>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
        v-if="!loading"
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :row-key="record => record.id"
        :pagination="false"
        :defaultExpandAllRows="true"
        :scroll="{ y: 600 }"
        :locale="locale"
    >
      <!-- 展开图标 -->
      <template slot="expandIcon" slot-scope="props">
        <span>
            <div v-if="props.expanded" style="display: inline-block; margin-right: 10px"
                 @click="(e) => {props.onExpand(props.record, e)}">
                <a-icon type="caret-down" v-if="props.record.level !=3"/>
                <a-icon type="tag" theme="filled" v-else/>
            </div>
            <div v-else style="display: inline-block; margin-right:10px"
                 @click="(e) => {props.onExpand(props.record, e)}">
                <a-icon type="caret-right" v-if="props.record.level !=3"/>
                <a-icon type="tag" theme="filled" v-else/>
            </div>
           <span
               :class="props.record.level == 3 && form.labelName && props.record.labelName.indexOf(form.labelName) != -1 ?'color' : ''">{{
               props.record.labelName
             }}</span>
           <span v-if="props.record.level != 3">({{ props.record.children.length }})</span>
        </span>
      </template>


      <div slot="allLabel" slot-scope="text, record">
        <span v-if="record.level == 3">{{ record.useType == 1 ? "手动" : "自动" }}</span>
      </div>
      <div slot="useType" slot-scope="text, record">
        <span v-if="record.level == 3">{{ record.useType == 1 ? "手动" : "自动" }}</span>
      </div>
      <div slot="labelName" slot-scope="text, record">
        <span v-if="record.level == 3">{{ getLabelName(record) }}</span>
      </div>
      <div slot="effectTimeType" slot-scope="text, record">
        <span v-if="record.level == 3">{{ record.effectTimeType == 1 ? "按周" : "-" }}</span>
      </div>
      <div slot="mtime" slot-scope="text, record">
        <span v-if="record.level == 3">{{ record.mtime }}</span>
      </div>
      <div slot="action" slot-scope="text, record">
        <base-button
            v-if="record.level == 3"
            :type="'link'"
            :title="'编辑'"
            @onClickBtn="jumpDetailPage(record.id)"
        ></base-button>
      </div>
    </a-table>
  </div>
</template>

<script>
import Api from "@/api/tagManage";
import vEmpty from "@/components/no_data_yet";

export default {
  data() {
    return {
      form: {
        labelName: ""
      },
      loading: false,
      columns: [
        {
          align: "left",
          title: "全部标签",
          width: 240,
          // dataIndex: "labelName",
          // scopedSlots: {customRender: "allLabel"},
        },
        {
          align: "left",
          title: "标签值",
          scopedSlots: {customRender: "labelName"},
          width: 400,
        },
        {
          align: "center",
          title: "更新方式",
          scopedSlots: {customRender: "useType"}
        },
        {
          align: "center",
          title: "生效周期",
          scopedSlots: {customRender: "effectTimeType"}
        },
        {
          align: "left",
          title: "更新时间",
          scopedSlots: {customRender: "mtime"}
        },
        {
          align: "center",
          title: "操作",
          key: "action",
          width: 100,
          fixed: "right",
          scopedSlots: {customRender: "action"}
        }
      ],
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      locale: {
        emptyText: <vEmpty text="未找到结果" description="请检查你的输入或尝试别的关键词"/>,
      },
    };
  },
  mounted() {
    this.getTagList();
  },
  methods: {
    handleSearch() {
      this.getTagList();
    },
    handleReset() {
      this.form.labelName = "";
      this.getTagList();
    },
    getTagList() {
      this.loading = true;
      let params = {
        labelName: this.form.labelName
      };
      Api.getTagList(params).then((res) => {
        if(res.code == 200) {
          this.dataList = res.data.map(item => {
            item.children?.map(a => {
              a.children?.map(k => {
                k.kids = _.cloneDeep(k.children);
                delete k.children;
                return k;
              });
              return a;
            });
            return item;
          });
          this.loading = false;
        } else {
          this.loading = false;
          this.dataList = [];
          console.error(`获取列表失败${ res }`);
        }
      });
    },
    jumpDetailPage(id) {
      this.$router.push({
        path: `/application/tagManage/tagEdit`,
        query: {
          id
        }
      });
    },
    getLabelName(record) {
      let text = "";
      let labelName = [];
      record.kids?.map(k => {
        labelName.push(k.labelName);
        return text = `共${ labelName.length }个：` + labelName.join("，");
      });
      return text || "-";
    }
  }
};
</script>
<style lang="less" scoped>
.color {
  color: #40a9ff;
}
</style>
