<template>
  <div class="empty_box">
    <a-icon :type="icon" style="font-size:40px"/>
    <div style="font-size: 20px;padding: 15px 0 0 0">{{ text }}</div>
    <div>{{ description }}</div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: "暂无数据"
    },
    description: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: "file-search"
    }
  }
};
</script>
<style lang="scss" scoped>
.empty_box {
  width: 400px;
  margin: 26px auto;
}
</style>
